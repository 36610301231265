<template>
  <div
    :class="[
      'custom-controls no-select',
      { isFullScreen },
      { isHorizontalSwipe },
    ]"
  >
    <button
      @click="$emit('pause-video')"
      v-if="!format.hideControlBar"
      class="control-icon play-control"
      :class="{
        'control-icon-fullscreen': isFullScreen,
        'control-icon-inline': !isFullScreen && !isEmbedMobile,
        'control-icon-embed-without-product': isEmbedMobile && !videoHasProducts,
        'control-icon-embed-with-product': isEmbedMobile && videoHasProducts && !isLandscape  
      }"
    >
      <PlayMinimal v-if="isPaused" class="play-icon" />
      <PauseMinimal v-else class="pause-icon" />
    </button>
    <div
      v-if="!format.hideControlBar"
      ref="seekBar"
      class="seekbar"
      @mousedown="handleMouseDown"
      @mousemove="handleMouseMove"
      @mouseup="handleMouseUp"
      @touchstart="handleMouseDown"
      @touchmove="handleMouseMove"
      @touchend="handleMouseUp"
    >
      <span
        ref="progress"
        class="progress"
        :style="{ width: `${this.seekPercentage}%` }"
      ></span>
      <div ref="tooltip" v-show="tooltipVisible" class="vidjet-time-tooltip">
        00:00
      </div>
    </div>
  </div>
</template>

<script>
import enums from "@/enums.js";
import { mapGetters } from "vuex";
const { CtaType } = enums;

export default {
  components: {
    PlayMinimal: () => import("@/assets/play-pause-icons/play-minimal.vue"),
    PauseMinimal: () => import("@/assets/play-pause-icons/pause-minimal.vue"),
  },
  props: {
    videoIndex: {
      type: Number,
      default: 0,
    },
    isMuted: {
      type: Boolean,
      default: true,
    },
    isPaused: {
      type: Boolean,
      default: true,
    },
    currentTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // controls
      isDragging: false,
      seekPercentage: 0,
      tooltipVisible: false,
    };
  },

  watch: {
    currentTime() {
      this.updateSeekbar();
    },
  },

  mounted() {},

  computed: {
    ...mapGetters("videos", ["getVideoByIndex", "isLandscape",]),
    ...mapGetters("playerCampaign", [
      "format",
      "isFullScreen",
      "isHorizontalSwipe",
      "isEmbed",
    ]),
    ...mapGetters("player", ["isMobile", "isInPreview"]),

    video() {
      return this.getVideoByIndex(this.videoIndex);
    },
    isEmbedMobile() {
      return this.isEmbed && this.isMobile && !this.isInPreview;
    },
    videoHasProducts() {
      return (
        this.cta.ctaType === CtaType.product && this.cta.products.length >= 1
      );
    },
    cta() {
      return this.video.cta ?? {};
    },
  },

  methods: {
    updateSeekbar() {
      this.seekPercentage = this.helper_getPercentage(
        this.currentTime,
        this.video.videoDuration
      );
    },

    helper_getPercentage(presentTime, totalTime) {
      var calcPercentage = (presentTime / totalTime) * 100;
      return parseFloat(calcPercentage.toString());
    },

    handleMouseDown(event) {
      this.$emit("pause-video", true);
      this.isDragging = true;
      this.updateProgress(event);
    },
    handleMouseMove(event) {
      this.updateTooltip(event);
      if (this.isDragging) {
        this.updateProgress(event);
      }
    },
    handleMouseUp() {
      this.$emit("pause-video", false);
      this.isDragging = false;
      setTimeout(() => this.hideTooltip(), 500);
    },

    updateProgress(event) {
      const seekbar = this.$refs.seekBar;
      const clickPosition =
        (event.clientX || event.changedTouches[0].screenX) -
        this.$refs.seekBar.getBoundingClientRect().left;
      let progressPercentage = (clickPosition / seekbar.offsetWidth) * 100;
      // Ensure progressPercentage stays within 0 to 100
      progressPercentage = Math.max(0, Math.min(100, progressPercentage));
      // Update seekPercentage for visual feedback
      this.seekPercentage = progressPercentage;

      // Example: Update video currentTime based on progress
      const newTime = (progressPercentage / 100) * this.video.videoDuration;
      this.$emit("update-player-time", newTime);
    },

    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time - minutes * 60);

      const minutesString = (minutes < 10 ? "0" : "") + minutes;
      const secondsString = (seconds < 10 ? "0" : "") + seconds;

      return minutesString + ":" + secondsString;
    },

    updateTooltip(event) {
      const seekBarEl = this.$refs.seekBar;
      const seekBarRect = seekBarEl.getBoundingClientRect();
      const duration = this.video.videoDuration;

      const clientX = event.clientX - seekBarRect.left;
      const seekBarStart = 0;
      const seekBarWidth = seekBarRect.width;

      const mouseTime = ((clientX - seekBarStart) / seekBarWidth) * duration;

      const tooltip = this.$refs.tooltip;
      tooltip.innerHTML = this.formatTime(mouseTime);
      tooltip.style.left = `${clientX}px`;
      tooltip.style.bottom = `${seekBarRect.height + 15}px`;
      this.tooltipVisible = true;

      setTimeout(() => !this.isDragging && (this.tooltipVisible = false), 2000);
    },

    hideTooltip() {
      this.tooltipVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.custom-controls {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  z-index: 10;
  .control-icon {
    position: absolute;
    bottom: 70px;
    right: 16px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgba(60, 60, 60, 0.6);
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
      transform: scale(1.1);
    }
  }

  &.isFullScreen:not(.isHorizontalSwipe) .control-icon {
    height: 40px;
    width: 40px;
    svg {
      transform: scale(1.2);
    }
  }

  .control-icon-fullscreen {
    bottom: 160px;
    right: 16px;
    left: unset;
  }

  .play-icon {
    height: 12px;
    width: 12px;
  }

  .pause-icon {
    height: 12px;
    width: 12px;
  }

  .seekbar {
    width: 100%;
    height: 10px;
    background-color: #808080d1;
    cursor: pointer;
    overflow: hidden;
    &:hover {
      height: 15px;
      transition: all ease 0.25s;
    }
  }

  .isMobile .seekbar:hover {
    height: 20px;
  }

  .seekbar .progress {
    background-color: #efefef;
    width: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
  }
}

.vidjet-time-tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  pointer-events: none;
  transform: translateX(-50%);
  z-index: 20;
}

.custom-controls .control-icon-inline {
  right: 8px;
  left: unset !important;
}

.control-icon-embed-without-product {
  right: 8px !important;
  bottom: 70px !important;
}

.control-icon-embed-with-product {
  right: 8px !important;
  bottom: 160px !important;
}
</style>
