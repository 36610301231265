var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'custom-controls no-select',
    { isFullScreen: _vm.isFullScreen },
    { isHorizontalSwipe: _vm.isHorizontalSwipe },
  ]},[(!_vm.format.hideControlBar)?_c('button',{staticClass:"control-icon play-control",class:{
      'control-icon-fullscreen': _vm.isFullScreen,
      'control-icon-inline': !_vm.isFullScreen && !_vm.isEmbedMobile,
      'control-icon-embed-without-product': _vm.isEmbedMobile && !_vm.videoHasProducts,
      'control-icon-embed-with-product': _vm.isEmbedMobile && _vm.videoHasProducts && !_vm.isLandscape  
    },on:{"click":function($event){return _vm.$emit('pause-video')}}},[(_vm.isPaused)?_c('PlayMinimal',{staticClass:"play-icon"}):_c('PauseMinimal',{staticClass:"pause-icon"})],1):_vm._e(),(!_vm.format.hideControlBar)?_c('div',{ref:"seekBar",staticClass:"seekbar",on:{"mousedown":_vm.handleMouseDown,"mousemove":_vm.handleMouseMove,"mouseup":_vm.handleMouseUp,"touchstart":_vm.handleMouseDown,"touchmove":_vm.handleMouseMove,"touchend":_vm.handleMouseUp}},[_c('span',{ref:"progress",staticClass:"progress",style:({ width: `${this.seekPercentage}%` })}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tooltipVisible),expression:"tooltipVisible"}],ref:"tooltip",staticClass:"vidjet-time-tooltip"},[_vm._v(" 00:00 ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }